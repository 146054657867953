import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Label, Input } from "reactstrap"

import { TextInput, NumberInput, SexInput } from "../UserProfileFormBlocks"
import { UserProfileForm } from "../UserProfileFormBlocks/styles"

const UserProfileEditorDisplay = ({
  student,
  handleChange,
  handleSubmit,
  handleLogout,
}) => (
  <UserProfileForm onSubmit={handleSubmit}>
    <h1 style={{ marginLeft: "30px" }}>Edit Profile</h1>
    <TextInput
      id="user-alias"
      name="alias"
      label="How should we call you?"
      handleChange={handleChange}
      value={student?.alias}
      type="text"
    />
    <NumberInput
      id="age"
      label="Age"
      handleChange={handleChange}
      min={5}
      max={12}
      value={student?.age || 6}
      small
    />
    {/* <TextInput
      id="month-select"
      name="month-select"
      label="What is your birth month?"
      handleChange={handleChange}
      value={student?.month || "January"}
      type="select"
    >
      <option>January</option>
      <option>February</option>
      <option>March</option>
      <option>April</option>
      <option>May</option>
      <option>June</option>
      <option>July</option>
      <option>August</option>
      <option>September</option>
      <option>October</option>
      <option>November</option>
      <option>December</option>
    </TextInput> */}
    <FormGroup>
      <Input
        type="select"
        id="month-select"
        name="month"
        value={student?.month}
        onChange={handleChange}
      >
        <option value={null}>--Please select your birth month</option>
        <option value="January">January</option>
        <option value="February">February</option>
        <option value="March">March</option>
        <option value="April">April</option>
        <option value="May">May</option>
        <option value="June">June</option>
        <option value="July">July</option>
        <option value="August">August</option>
        <option value="September">September</option>
        <option value="October">October</option>
        <option value="November">November</option>
        <option value="December">December</option>
      </Input>
    </FormGroup>
    <SexInput
      handleChange={handleChange}
      sex={student?.gender || "female"}
      small
    />

    <nav>
      <button className="button button--medium" type="submit">
        Save
      </button>
      <button className="button button--medium logout" onClick={handleLogout}>
        Log Out
      </button>
    </nav>
  </UserProfileForm>
)

UserProfileEditorDisplay.propTypes = {
  student: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleLogout: PropTypes.func,
}

export default UserProfileEditorDisplay
