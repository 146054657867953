import React, { useEffect } from "react"
import { useWindowSize } from "react-use"
import { navigate } from "gatsby"

import Layout from "../../../components/layout.js"
import Seo from "../../../components/seo.js"
import UserProfileEditor from "../../../components/UserProfileEditor/index.js"
import ProfileLayout from "../../../components/UserProfileLayout/index.js"
import { updateCurrentStudentData } from "../../../services/helpers"

const ProfileEditorPage = () => {
  const isBrowser = typeof window !== "undefined"

  const checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  useEffect(() => {
    checkStudentData()
  }, [])

  const { height } = useWindowSize()

  return (
    <Layout layoutHeight={height}>
      <Seo title="Profil bearbeiten" />
      <ProfileLayout backLinkTarget="/student/profile">
        <UserProfileEditor />
      </ProfileLayout>
    </Layout>
  )
}

export default ProfileEditorPage
